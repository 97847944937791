<template>
  <div class="news">
    <div class="title gn-flex-center gn-font-bold">新闻动态</div>
    <div class="tabs">
      <ul>
        <li
          v-for="(item, index) in list"
          :key="index"
          :class="index == tabKey ? 'active_tabs' : ''"
          @click="handleCheck(index)"
        >
          {{ item.title }}
        </li>
      </ul>
    </div>
    <div class="main">
      <div class="content">
        <ul>
          <li
            v-for="(item, index) in list[tabKey].dataList"
            :key="index"
            @click="goDetail(item)"
          >
            <div class="video gn-rela">
              <img :src="item.title_image" class="gn-full" />
              <div v-if="tabKey == '1'" class="icon gn-center">
                <img src="@/assets/images/newbazaarIntroduce/video.png" />
              </div>
            </div>
            <div class="info">
              <div class="title gn-line-1">{{ item.title }}</div>
              <div class="time">{{ item.create_time }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <load-more :status='list[tabKey].loadStatus' @load="loadMore"></load-more>
  </div>
</template>

<script>
import { getArticleList } from '@/api/admin'

export default {
  data () {
    return {
      tabKey: '0',
      list: [
        {
          title: '公司新闻',
          page: 1,
          per_page: 8,
          dataList: [],
          loadStatus: 'loadmore'
        }
        // {
        //   title: "行业动态",
        //   page: 1,
        //   per_page: 8,
        //   dataList: [],
        //   loadStatus: "loadmore",
        // },
        // {
        //   title: '市场巡展',
        //   page: 1,
        //   per_page: 8,
        //   dataList: [],
        //   loadStatus: 'loadmore'
        // }
      ],
      reqParams: {
        page: 1,
        per_page: 8,
        search_data: '', // 关键字搜索
        advisory_type: '1', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      }
    }
  },
  created () {
    const { type } = this.$route.query
    if (type) {
      this.changeSelectItem(type)
    } else {
      this.getList()
    }
  },
  methods: {
    changeSelectItem (type) {
      this.handleCheck(type)
    },
    async getList () {
      const currentItem = this.list[this.tabKey]
      this.reqParams.page = currentItem.page
      this.reqParams.per_page = currentItem.per_page

      const { list, count } = await getArticleList(this.reqParams)

      if (currentItem.page == 1) {
        currentItem.dataList = list || []
      } else {
        currentItem.dataList = [...currentItem.dataList, ...list]
      }
      if (currentItem.dataList.length === 0) {
        currentItem.loadStatus = 'empty'
      } else if (currentItem.dataList.length >= count) {
        currentItem.loadStatus = 'nomore'
      } else {
        currentItem.loadStatus = 'loadmore'
      }
    },
    handleCheck (key) {
      this.tabKey = key == 0 ? 0 : 1

      // 切换列表数据
      this.list[this.tabKey].page = 1
      this.reqParams.advisory_type = key == 0 ? '1' : '3'
      this.getList()
    },
    loadMore () {
      const currentItem = this.list[this.tabKey]

      if (currentItem.loadStatus === 'loading') return
      currentItem.loadStatus = 'loading'
      currentItem.page += 1
      setTimeout(() => {
        this.getList()
      }, 500)
    },
    goDetail (item) {
      // 1:公司新闻  2:行业动态  3:视频展示
      if (item.advisory_type === '3') {
        this.$router.push({
          name: 'mobileVideo',
          query: {
            type: item.advisory_type, // 1:公司新闻  2:行业动态  3:视频展示
            videoUrl: item.video || '',
            name: item.title || '',
            cover: item.title_image || ''
          }
        })
      } else {
        this.$router.push({
          name: 'mobileNewsDetail',
          query: {
            type: item.advisory_type,
            title: item.title
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .more .text{
    font-size: 14px !important;
    i{
      font-size: 14px !important;
    }
  }
.news {
  margin-top: 79px;
  margin-bottom: 35px;

  .title {
    font-size: 20px;
    color: #333333;
  }
  .tabs {
    display: flex;
    justify-content: flex-start;
    margin-top: 23px;
    width: 100%;
    border-bottom: 1px solid #cccccc;
    ul {
      display: flex;
      li {
        margin: 0px 30px;
        padding-bottom: 10px;
        font-size: 14px;
        color: #333333;
        box-sizing: border-box;
        cursor: pointer;
      }
      li.active_tabs {
        font-weight: 700;
        border-bottom: 2px solid #00a0ac;
      }
    }
  }
  .main {
    padding: 24px 10px;
    padding-bottom: 10px;
    .content {
      width: 100%;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 48.5%;
          height: auto;
          margin-bottom: 10px;
          .video {
            width: 100%;
            height: 90px;
            img {
              width: 100%;
              height: 100%;
            }
            .icon {
              width: 39px;
              height: 39px;
            }
          }
          .info {
            width: 100%;
            background: #F5F5F5;
            height: auto;
            padding-left: 15px;
            padding-top: 10px;
            padding-bottom: 15px;
            box-sizing: border-box;
            margin-bottom: 5px;
            .title {
              font-size: 14px;
              color: #333333;
              padding: 0;
              margin-bottom: 5px;
            }
            .time {
              font-size: 10px;
              color: #666666;
              padding: 0;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
  :deep(.more .text) {
    font-size: 14px;
  }
}
</style>
